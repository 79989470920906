import * as React from 'react'
import Axios from "axios";
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { deleteDownloadUrl, selectDownloadsState } from "../sagas/downloads/downloadsSlice";

const Ursula_Token = process.env.GATSBY_URSULA_TOKEN;

export default function DownloadItem({ url, iKey, handleDelete, id, total }) {
    const dispatch = useDispatch();
    const { isDeletingDownloadUrlSuccess, isDeletingDownloadUrlFailed } = useSelector(selectDownloadsState);
    
    const [selectedKey, setSelectedKey] = React.useState();
    const onDeleteClicked = (key) => {
        setSelectedKey(key);

        Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm'
          }).then((result) => {
            if (result.isConfirmed) {
                Axios.delete(
                    "https://ursula.99gens-api.com/api/admin/dielines/delete",
                    {
                        headers: {
                            'Authorization': `Bearer ${Ursula_Token}`,
                        },
                        data: { url },
                    }
                ).then((response) => {
                    dispatch({
                        type: deleteDownloadUrl.type,
                        payload: { url },
                    });
                }).catch((error) => { console.log("This is an error:", error) })
            }
        })
    }

    React.useEffect(() => {
        if (isDeletingDownloadUrlSuccess) {
            console.log("successfully deleted");
            handleDelete(selectedKey);
        } else if (isDeletingDownloadUrlFailed) {
            console.log("Something went wrong")
        }
    }, [isDeletingDownloadUrlSuccess, isDeletingDownloadUrlFailed])

    return (
        <>
            <div className="d-item" key={{id}}>
                <div className="d-url" ><span>{ url ? (url.split("/"))[4] : ''}</span><span style={{display: 'none'}}>{url}</span></div>
                <div className="d-btn-container" style={{display: 'flex'}}>
                    <div className="d-btn"><a href={url} className="btn btn-blue">Download</a></div>
                    <div className="d-btn d-btn-delete" ><a href="#" onClick={() => {
                        onDeleteClicked(id);
                    }} className="btn btn-blue">Delete</a></div>
                </div>
            </div>
            {
                iKey < total -1 ? <div className="d-divider"></div> : <></>
            }
      </>
    )
}