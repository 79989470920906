import React from "react";
import Header from "../components/header";
import Footer1 from "../components/footer-1";
import { isLoggedIn } from "../services/auth";
import { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";
import DownloadItem from "../components/downloadItem";
import { getUserFromAccessToken } from "../services/auth";
import { useDispatch, useSelector } from 'react-redux';
import { getSavedUrls, selectDownloadsState } from "../sagas/downloads/downloadsSlice";

export default function Layout() {
  const dispatch = useDispatch();
  const { isFetchingSavedUrlsSuccess, isFetchingSavedUrlsFailed, SavedUrlsData } = useSelector(selectDownloadsState);

  const [urlDatas, setUrlDatas] = useState([]);

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/signin");
      return;
    }

    const currentUser = getUserFromAccessToken();

    if (currentUser.role != 'SUPERADMIN') {
      navigate("/beta");
      return;
    }

    dispatch(getSavedUrls());
  }, []);

  useEffect(() => {
    if (isFetchingSavedUrlsSuccess) {
      setUrlDatas(SavedUrlsData);
    } else if (isFetchingSavedUrlsFailed) {
      console.log('something went wrong');
    }
  }, [isFetchingSavedUrlsSuccess, isFetchingSavedUrlsFailed, SavedUrlsData])

  const handleDelete = (id) => {
    setUrlDatas(prev => {
      return prev.filter((item) => {
        return item.id !== id
      })
    })
  }

  return (
    <>
      <Helmet>
      </Helmet>
      <div className="container-main" id="page">
        <Header></Header>
          <main className="content-main">
            <div className="downloads-container">
                  <div className="container">
                    <h1>Downloads</h1>
                      <div className="d-list">
                        {
                          urlDatas ?
                            urlDatas.map((item, index) => 
                            <DownloadItem url={item.url} total={urlDatas.length} iKey={index} id={item.id} handleDelete = {handleDelete}>
                            </DownloadItem>
                            )
                          : <></>
                        }
                      </div>
                  </div>
            </div>
          </main>
        <Footer1></Footer1>
      </div>
    </>
  );
}
